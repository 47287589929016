import SpaceMCBanner from './SpaceMCBanner';
import './Page.css';
import { ConnectionDetails } from './ConnectionDetails';
import JavaIcon from '../resources/java-icon.png';
import BedrockIcon from '../resources/bedrock-icon.png';
import DiscordIcon from '../resources/discord-mark-blue.png';

function Page() {
    return (
        <div>
            <SpaceMCBanner/>
            <div className='main-page-container'>
                <div className='middle-container'>
                    <div className='center-flex-column'>
                        <h2 className='page-header' id='rules-header'>Rules</h2>
                        <ol>
                            <li>Please do not do major events without setting a time & day/giving ample opportunity for others to join! That could include things like:
                                <ul>
                                    <li>First time going to the <b>Nether.</b></li>
                                    <li>Looting the first <b>Ancient City.</b></li>
                                    <li><b>The End</b> - preferably would be done with as many people as possible.</li>
                                    <li>Basically, things that are better together. If unsure, maybe ask others anyway!</li>
                                </ul>
                            </li>
                            <li>
                                Servers tend to die off after doing The End. Thus, please don't try to speedrun the server. Let's make it last a little while!
                            </li>
                        </ol>
                    </div>
                    <hr/>
                    <div>
                        <div className='center-flex-column'>
                            <h2 className='page-header' id='connection-header'>Connection Details</h2>
                            <h4>Go ahead and try out your connection ahead of server launch!</h4>
                            <div className='center-flex-column'>
                                <div className='center-flex-row'>
                                    <ConnectionDetails img={JavaIcon} edition="JAVA" ip="mc.space-force.lol" port="25565 (should be automatic)" instructions="Click 'Multiplayer' -> 'Add Server', then add the server details."/>
                                    <ConnectionDetails img={BedrockIcon} edition="BEDROCK" ip="br.mc.space-force.lol" port="19132" instructions="Click 'Play', go to the 'Servers' tab, and scroll down to 'Add Server'. Add the server details."/>
                                </div>
                            </div>
                        </div>
                    </div>
                    <hr/>
                    <div className='console-container'>
                        <h2><i>for those playing on console...</i></h2>
                        <p>For some reason console editions don't inherently support servers (other than the built-in ones).
                            So, you'll need to connect via one of these two methods:
                        </p>
                                <h3>Method 1: Changing your console's network settings.</h3>
                                <p><i>You just need to change your network settings as shown, which intercepts the usual server connection. Note that this video is for Xbox, but the same applies to other consoles.</i></p>
                                <iframe width="auto" height="auto" src="https://www.youtube.com/embed/0vIhBWaBDig?si=FElT8awA4EEZZY1k" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                                <h3>Method 2: Using your phone as a bridge to the server.</h3>
                                <p><i>Download an app, point it to the server, then connect to your phone via Wifi.</i></p>
                                <iframe width="auto" height="auto" src="https://www.youtube.com/embed/qKlysVRSeso?si=fnbTe8rnGz1icYVR" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                    </div>
                    <p/>
                    <hr/>
                    <div className='center-flex-column'>
                        <h1 id='discord'>Join the Space Force Discord</h1>
                        <p id='discord-subtitle'>This will be the hub for voice chat for whenever folks are playing.</p>
                        <p id='join-here'>Join here:
                        <a title='Join the SF Discord' href="https://discord.gg/WyndWwczYC" target='_blank'>
                            <img src={DiscordIcon} className='discord-image'/>
                        </a>
                        </p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Page;