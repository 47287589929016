import './SpaceMCBanner.css';

function SpaceMCBanner(){
    return (
        <div className='top-banner'>
            <div className='space-force-logo'> 

            </div>
        </div>
    )
}

export default SpaceMCBanner;