import './ConnectionDetails.css'

export const ConnectionDetails = (props) =>
    <div className='connection-details-container'>
        <img className='edition-icon' src={props.img}/>
        <h1>{props.edition}</h1>
        <p className='instructions'>{props.instructions}</p>
        <p className='connection-detail'><b>IP:</b> {props.ip}</p>
        <p className='connection-detail'><b>Port:</b> {props.port}</p>
        {/* <img src={props.screenshot}/> */}
    </div>